import ApplicationController from './application_controller';
import { UAParser } from 'ua-parser-js'; 

export default class extends ApplicationController {
  static targets = [
    'startLink',
    'demoLink',
    'pricePlansLink'
  ]
  static values = {
    priceplansUrl: String,
    freePlanEnabled: Boolean
  }

  connect() {
    const uaParser = new UAParser();
    const device = uaParser.getDevice();
    const browser = uaParser.getBrowser();
    const isChromeDesktop = browser.name == 'Chrome' && (device.type === undefined || !['wearable', 'mobile'].includes(device.type));

    if (typeof currentuserData !== 'undefined' && currentuserData.is_premium) {
      // For logged in users, remove any start/trial links
      // and just show them price plan urls
      if (this.hasDemoLinkTarget) {
        this.demoLinkTarget.remove();
      }

      if (this.hasStartLinkTarget) {
        this.startLinkTarget.remove();
      }

      // Remove any upsell params on the URL for logged in users
      if (this.hasPricePlansLinkTarget) {
        this.pricePlansLinkTarget.href = this.priceplansUrlValue;
      }
    } else {
      // Only show the free plan for users on chrome desktop as this supports browser broadcasting
      if (this.freePlanEnabledValue && isChromeDesktop) {
        this.startLinkTarget.classList.remove('!hidden');
        this.pricePlansLinkTarget.classList.remove('button--primary');
        this.pricePlansLinkTarget.classList.add('button--outline');
      }
    }

    this.element.classList.remove('opacity-0');
    this.element.classList.add('animate-fadeIn');
  }
}